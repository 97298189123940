function Header() {
  return (
      <header className="header">
        <div className="logo"><img src={process.env.PUBLIC_URL + '/logo-white-square.jpg'} /><p><span>LA</span> Capital Group</p></div>
        <div className="navigation">
            <a href="/#home">Home</a>
            <a href="/#services">Services</a>
            <a href="/contact-us">Contact Us</a>
            <a className="apply-button" href="https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=7b14059d-5d68-4ff4-9a73-37898f1fca7d&env=na4&acct=5e685ab4-2350-4bbc-9b6f-1d1ecac9e429&v=2" target="_blank">Apply Now</a>
        </div>
      </header>
  );
}

export default Header;
