import Header from './Header.js';
import Footer from './Footer.js';
import { useState } from 'react';

function ContactUs() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("Send Message")
  const [disabled, setDisabled] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setValue("Message Sent");
    setDisabled(true);
    
    var request = new XMLHttpRequest();
    request.open("POST", "https://postmail.invotes.com/send", true);
    request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    var data = {
      "access_token": "qugkvo8zj91ugz2d280lo6gh"
    };
    data['extra_first_name'] = firstName;
    data['extra_last_name'] = lastName;
    data['reply_to'] = email;
    data['extra_phone'] = phone;
    data['subject'] = subject;
    data['text'] = message;
    var params = toParams(data);
    request.send(params);
  }

  function toParams(data) {
    var form_data = [];
    for ( var key in data ) {
        form_data.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
    }

    return form_data.join("&");
}

  return (
      <div>
        <Header />
        <div className="contact contact-page">
        <a name="contact" className="anchor"></a>
        <div className="contact-inner">
          <div>
            <div className="contact-left">
              <h1>Get In Touch</h1>
              <p>Reach out to us for personalized financial support and expert advice.</p>
              <div className="contact-left-row">
                <a href="tel:+18886687960">
                  <svg aria-hidden="true" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                </a>
                <span>
                  <h5>Phone</h5>
                  <p>+1 888 668 7960</p>
                </span>
              </div>
              <div className="contact-left-row">
                <a href="mailto:info@lacapitalgrp.com">
                  <svg aria-hidden="true" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                </a>
                <span>
                  <h5>Email</h5>
                  <p>info@lacapitalgrp.com</p>
                </span>
              </div>
              <h4>Follow Our Social Media</h4>
              <div className="socials">
                <a href="https://www.facebook.com/share/GzUSLW51A6hL9HRR/?mibextid=qi2Omg" target="_blank">
                  <svg viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                </a>
                <a href="https://instagram.com/lacapitalgrp" target="_blank">
                <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                </a>
              </div>
            </div>
            <div className="contact-right">
              <h1>Contact Us</h1>
              <p>Fill out the form below to connect with our team and discover how we can help your business thrive.</p>
              <form action="https://postmail.invotes.com/send"
    method="post" id="email_form" onSubmit={(e) => handleSubmit(e)}>

    <input type="hidden" name="access_token" value="qugkvo8zj91ugz2d280lo6gh" />
    <input type="hidden" name="success_url" value=".?message=Email+Successfully+Sent%21&isError=0" />
    <input type="hidden" name="error_url" value=".?message=Email+could+not+be+sent.&isError=1" />
   
    
    <input required type="text" name="extra_first_name"
                placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
                <input required type="text" name="extra_last_name"
                placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
                <div className="flex-break"></div>
   <input required type="text" name="reply_to"
                placeholder="Your Email" value={email} onChange={e => setEmail(e.target.value)} />
                <input required type="text" name="extra_phone"
                placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
                                <div className="flex-break"></div>

    <input required type="text" name="subject" placeholder="Subject" value={subject} onChange={e => setSubject(e.target.value)} />
    <div className="flex-break"></div>

    <textarea required name="text" placeholder="Message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
    <div className="flex-break"></div>
    <input id="submit_form" type="submit" value={value} disabled={disabled} />
              </form>
            </div>
          </div>
        </div>
      </div>
        <Footer />
      </div>
  );
}

export default ContactUs;
