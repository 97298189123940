import Header from './Header.js';
import Footer from './Footer.js';

function PrivacyPolicy() {
  return (
      <div>
        <Header />
        <div className="service-page">
        <h1>Privacy Policy</h1>
        
<p><b>Last updated: 7-1-2024</b></p>

<p>Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand our privacy policies. CCConsulting Services Inc. DBA LA Capital Group (“The Company”, “we,” or “us“), a North Carolina  .Corp (“The Company”)  services are not directed to individuals under 18 and we do not knowingly collect data relating to these individuals.</p>

<p>At the Company, we are committed to maintaining the trust and confidence of our visitors to our website and privacy of our Customers. In this Privacy Policy, we've provided detailed information on when and why we collect personal information, how we use it, the limited conditions under which we may disclose it to others, and how we keep it secure.</p>

<p>1. With your consent and knowledge, we will collect including but not limited to:</p>
<ul>
<li>Personal Information such as - Social Security number, income, account balances and transaction history:  business name, business address, email address, phone number, Government issued identity document such State ID or Drivers licenses,</li>
<li>Company information such as Employer Identiﬁcation number, proof of legal formation and status, information from other beneficial owners of your Company, Bank account information, Tax return information, Proﬁt and Loss Statements, Balance sheet (including details of any debt on your Balance Sheet), Business Invoices, Business address, Type of business, and List of oﬃcers, members, or managers of the business,</li>
<li>Website Usage Data: Information about how you use our website, including time of visit, pages viewed, and website navigation paths.</li>
<li>We may obtain information from credit bureaus, including your credit report. We will not disclose your credit report without your explicit permission. We obtain your credit information to better provide services to you, to match you with appropriate lenders, to ensure your identity, and to avoid fraud.</li>
<li>We may obtain information directly from your ﬁnancial institutions, including copies of your account statements and bank statements. This information along with other personal and financial information will provide our lenders with a full picture of you financial heal and better serve you during the underwriting process.</li>
</ul>

<p>2. How Is Your Personal Data Collected?</p>
<p>We use different methods to collect data from and about you including through:</p>
<ul>
  <li>Direct interactions. You may give us your Identity, Contact, and Financial Data by filling in forms or by corresponding with us by post, phone, email, or otherwise. This includes personal data you provide when you:</li>
<ul>
  <li>apply for our products or services;</li>
  <li>create an account on our website;</li>
  <li>subscribe to our service or publications;</li>
  <li>request marketing to be sent to you;</li>
  <li>enter a competition, promotion, or survey; or</li>
  <li>give us feedback or contact us.</li>
</ul>
<li>Automated technologies or interactions. As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions, and patterns. We collect this personal data by using cookies, server logs, and other similar technologies.</li>
<li>Third parties or publicly available sources. We may receive personal data about you from various third parties and public sources.</li>
</ul>

<p>3. How We Use Your Information</p>
<p>Your information may be used for the following purposes:</p>
<ul>
  <li>To Provide Our Services: Managing your account, providing financial services, and customer support.</li>
  <li>Communication: Responding to inquiries, sending service updates, and other necessary communication.</li>
  <li>Website Improvement: Enhancing website performance and user experience.</li>
  <li>Compliance and Legal Obligations: Fulfilling legal requirements and defending against legal claims.</li>
  <li>To provide our marketing team or third Web site information and how you interact with Third Party Service Providers:</li>
  <li>The Company uses third party providers. Therefore, certain of our products may be subject to third-party privacy policies.  The company uses Ocrolus, https://www.ocrolus.com/privacy-policy, and Plaid, https://plaid.com/legal/#consumers</li>
</ul>
<p>4. Data Protection</p>
<p>We take the security of your data seriously. We employ various physical, electronic, and managerial measures to ensure the data we collect is kept secure, confidential, and protected from unauthorized access, use, alteration, or destruction. We monitor, investigate, prevent, and mitigate any potentially prohibited or illegal activities, enforce our agreements with third parties, and/or prevent and detect violations of our posted user agreement or agreements for other Services. We limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know. They will only process your personal data on our instructions, and they are subject to a duty of confidentiality.</p>

<p>4. Sharing of Information</p>
<p>We do not sell, rent, or otherwise share personal information with third parties except in the following circumstances:</p>
<ul>
  <li>Service Providers: To third parties who perform services on our behalf.</li>
  <li>Legal Requirements: If required by law or to protect the rights and safety of our company and others.</li>
  <li>Business Transfers: In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
</ul>

<p>5. Your Rights</p>
<p>You have the right to access, correct, update, or request the deletion of your personal information. If you wish to exercise these rights, please contact us using the information below.</p>

<p>6. Changes to This Policy</p>
<p>We may update this Privacy Policy from time to time in response to legal, technical, or business developments. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make.</p>

<p>7. Data Retention</p>
<p>We will only retain your personal data for as long as reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements. We may retain your personal data for a longer period in the event of a complaint, or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>

<p>8. Disclosure By Law:</p>
<p>You acknowledge and agree that we may disclose information you provide if required to do so by law, at the request of a third party, or if we, in our sole discretion, believe that disclosure is reasonable to (1) comply with the law, requests or orders from law enforcement.</p>

<p>9. Service Providers</p>
<p>With service providers under contract who help with parts of our business operations. Our contracts require these service providers to only use your information in connection with the services they perform for us, and prohibit them from selling your information to anyone else. Examples of the types of service providers we may share personal information with</p>

<p>Cloud storage</p>
<p>Payment processing</p>
<p>Transaction monitoring</p>
<p>Security</p>
<p>Document repository services</p>
<p>Customer support</p>
<p>Data analytics</p>
<p>Marketing</p>


<p>10. California Privacy Notice</p>
<p>In addition to the rights provided for above, the information contained in this section applies solely to those individuals who reside in the State of California. We adopt this notice to comply with the California Consumer Privacy Act of 2018 and California Privacy Rights Act of 2020 any terms deﬁned in the CCPA have the same meaning when used in this Policy.  CCPA among other rights include:</p>
<p><i>Updated on July 1-2024</i></p>
<p>The <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5" target="_blank">California Consumer Privacy Act of 2018</a> (CCPA) gives consumers more control over the personal information that businesses collect about them and the <a href="https://govt.westlaw.com/calregs/Browse/Home/California/CaliforniaCodeofRegulations?guid=I5E53FC80FEDE11ECA3A49C17D1AA5D7C&originationContext=documenttoc&transitionType=Default&contextData=(sc.Default)" target="_blank">CCPA regulations</a> provide guidance on how to implement the law. This landmark law secures new privacy rights for California consumers, including:</p>
<ul>
  <li>The <a href="https://www.oag.ca.gov/privacy/ccpa#sectionc" target="_blank">right to know</a> about the personal information a business collects about them and how it is used and shared;</li>
<li>The <a href="https://www.oag.ca.gov/privacy/ccpa#sectiond" target="blank">right to delete</a> personal information collected from them (with some exceptions);</li>
<li>The <a href="https://www.oag.ca.gov/privacy/ccpa#sectionb" target="_blank">right to opt-out</a> of the sale or sharing of their personal information; and</li>
<li>The <a href="https://www.oag.ca.gov/privacy/ccpa#sectiong" target="_blank">right to non-discrimination</a> for exercising their CCPA rights.</li>
</ul>
<p>In November of 2020, California voters approved <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5" target="_blank">Proposition 24, the CPRA</a>, which amended the CCPA and added new additional privacy protections that began on January 1, 2023. As of January 1, 2023, consumers have new rights in addition to those above, such as:</p>
<ul>
<li>The <a href="https://www.oag.ca.gov/privacy/ccpa#sectione" target="_blank">right to correct</a> inaccurate personal information that a business has about them; and</li>
<li>The <a href="https://www.oag.ca.gov/privacy/ccpa#sectionf" target="_blank">right to limit</a> the use and disclosure of sensitive personal information collected about them.</li>
</ul>
<p>California residents have certain rights in relation to their personal information, subject to limited exceptions. Any terms deﬁned in the CCPA have the same meaning when used in this California Privacy Notice.</p>

<p>Sensitive personal information. Certain of the personal information that we collect, may constitute “sensitive personal information” under California law, including:</p>
<ul>
<li>Social security, driver's license, state identiﬁcation card, and/or passport number</li>
<li>Account log-in combined with any required security or access codes, passwords, or other</li>
<li>credentials allowing access to an account</li>
<li>Precise geolocation</li>
<li>Racial and/or ethnic origin</li>
<li>Personal information concerning sex life or sexual orientation</li>
</ul>


<p>The Company shall not collect additional categories of sensitive personal information or use sensitive personal information collected for additional purposes that are incompatible with the disclosed purpose for which the sensitive personal information was collected without providing the consumer with notice consistent with this section.</p>

<p>Retention Policy – The Company will retain any information we collect from our customers only as long that is necessary for the disclosed purpose of using such information. Our determination of precise retention periods will be based on (i) the length of time we have an ongoing relationship with the customer; (ii) whether there is a legal obligation to which we are subject; and (iii) whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation or regulatory investigations). The Company will initiate a procedure for deleting personal information.</p>  


<p>11. Your Legal Rights</p>
<p>Under certain circumstances, you have rights under data protection laws in relation to your personal data including the right to request access, correction, erasure, restriction, transfer, to object to processing, to portability of data, and (where the lawful ground of processing is consent) to withdraw consent.</p>

<p>12. Contact Us</p>
<p>If you have any questions about this Privacy Policy, please contact us by email at  or by mail at the following:</p>

<p>Contact Details</p>
<p>Our full details are:</p>
<p>Full Name of Legal Entity: CCConsulting</p>
<p>Services Inc. DBA LA Capital Group</p>
<p>Email address: info@lacapitalgrp.com</p>
<p>Postal address: 137 Dedham Loop Mooresville, NC 28117</p>
<p>You have the right to make a complaint at any time to the relevant data protection supervisory authority for your country.</p>


<p>13. Glossary</p>

<p>LAWFUL BASIS</p>
<ul>
<li>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).</li>
<li>Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
<li>Comply with a legal obligation means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</li>
</ul>

<p>YOUR LEGAL RIGHTS</p>
<p>You have the right to:</p>

<ul>
<li>Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
<li>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
<li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
<li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation that makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
<li>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have to override legitimate grounds to use it.</li>
<li>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information that you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
<li>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
</ul>

<p>14. How to Exercise Your Rights</p>
<p>If you wish to exercise any of the rights set out above, please contact us at : info@lacapitalgrp.com</p>
<p>No fee usually required. You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>

<p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

<p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

<p>15. Changes to This Privacy Policy</p>
<p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. We will let you know via email and/or a prominent notice on our website, prior to the change becoming effective, and update the "effective date" at the top of this privacy policy. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.</p>

</div>
        <Footer />
      </div>
  );
}

export default PrivacyPolicy;
