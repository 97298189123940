import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ShortTermFunding from './ShortTermFunding';
import BusinessLineOfCredit from './BusinessLineOfCredit';
import BridgeLoans from './BridgeLoans';
import EquipmentFinancing from './EquipmentFinancing';
import InventoryFinancing from './InventoryFinancing';
import InvoiceFinancing from './InvoiceFinancing';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import ContactUs from './ContactUs';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/short-term-funding",
    element: <ShortTermFunding />
  },
  {
    path: "/business-line-of-credit",
    element: <BusinessLineOfCredit />
  },
  {
    path: "/bridge-loans",
    element: <BridgeLoans />
  },
  {
    path: "/equipment-financing",
    element: <EquipmentFinancing />
  }
  ,
  {
    path: "/inventory-financing",
    element: <InventoryFinancing />
  }
  ,
  {
    path: "/invoice-financing",
    element: <InvoiceFinancing />
  }
  ,
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  }
  ,
  {
    path: "/terms-of-use",
    element: <TermsOfUse />
  }
  ,
  {
    path: "/contact-us",
    element: <ContactUs />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
