import Header from './Header.js';
import Footer from './Footer.js';
import { useState } from 'react';
import FadeInSection from './FadeInSection.js';
import FadeInSectionSlow from './FadeInSectionSlow.js';
import FadeInSectionRight from './FadeInSectionRight.js';
import FadeInSectionTop from './FadeInSectionTop.js';
import FadeInSectionBottom from './FadeInSectionTopBottom.js';

function App() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("Send Message")
  const [disabled, setDisabled] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setValue("Message Sent");
    setDisabled(true);
    
    var request = new XMLHttpRequest();
    request.open("POST", "https://postmail.invotes.com/send", true);
    request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    var data = {
      "access_token": "qugkvo8zj91ugz2d280lo6gh"
    };
    data['extra_first_name'] = firstName;
    data['extra_last_name'] = lastName;
    data['reply_to'] = email;
    data['extra_phone'] = phone;
    data['subject'] = subject;
    data['text'] = message;
    var params = toParams(data);
    request.send(params);
  }

  function toParams(data) {
    var form_data = [];
    for ( var key in data ) {
        form_data.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
    }

    return form_data.join("&");
}

  return (
    <div className="App">
      <a name="home"></a>
      <Header />
      <div className="hero">
      <FadeInSectionSlow>
        <h2>Integrity is first and foremost in our decision making process</h2>
      </FadeInSectionSlow>
        <FadeInSection>
        <h1>Grow with LA Capital Group's Financial Funding Expertise</h1>
        <h3>Maximize your business’s potential with funding solutions
        designed to steer your growth trajectory toward success.</h3>
        </FadeInSection>
        <FadeInSectionSlow>
        <a className="apply-button" href="https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=7b14059d-5d68-4ff4-9a73-37898f1fca7d&env=na4&acct=5e685ab4-2350-4bbc-9b6f-1d1ecac9e429&v=2" target="_blank">Apply Now</a>
        <a className="contact-button" href="#contact">Contact Us</a>
        </FadeInSectionSlow>
        
      </div>
      
      <div className="about-us">
      <FadeInSection>
        <div className="about-img-wrap">
        <img src={process.env.PUBLIC_URL + '/about-us.jpg'} />
        </div>
      </FadeInSection>
      <FadeInSectionRight>
        <div>
          <span className="heading-wrap-left">
            <h5>About Us</h5>
          </span>
          <h1>About Our Company</h1>
          <p>LA Capital Group understand that every business is unique and has special needs. We listen to our
customers to secure the right financing for their sustained growth and stability. We specialize in
providing a range of financial solutions designed to support small enterprises at every stage of their
development. We have one time CFOs, CPAs and real-estate experts who will work with
you and try to get funding for your business at competitive rates.</p>
        </div>
        </FadeInSectionRight>
      </div>

      <div className="choose-us">
        <div className="choose-us-inner">
        <FadeInSection>
        <div>
          <span className="heading-wrap-left">
            <h5>Why Choose Us</h5>
          </span>
          <h1>Why Choose LA Capital Group</h1>
          <div className="choose-row">
            <span className="choose-icon">
            <img src={process.env.PUBLIC_URL + '/expert.png'} />
            </span>
            <div className="choose-text">
              <p><b>Expert Advisors</b></p>
              <p>Tap into success with guidance from our seasoned financial experts</p>
            </div>
          </div>

          <div className="choose-row">
            <span className="choose-icon">
            <img src={process.env.PUBLIC_URL + '/seamless.png'} />
            </span>
            <div className="choose-text">
            <p><b>Seamless Communication</b></p>
            <p>Experience hassle-free interactions with our streamlined communication channels</p>
            </div>
          </div>

          <div className="choose-row">
            <span className="choose-icon">
            <img src={process.env.PUBLIC_URL + '/same-day.png'} />
            </span>
            <div className="choose-text">
            <p><b>Same-Day Funding</b></p>
            <p>Accelerate your ambitions with our rapid same-day funding capability</p>
            </div>
          </div>

          <div className="choose-row">
            <span className="choose-icon">
            <img src={process.env.PUBLIC_URL + '/multiple.png'} />
            </span>
            <div className="choose-text">
            <p><b>Multiple Services</b></p>
            <p>Diversify your growth opportunities with our comprehensive suite of financial services.</p>
            </div>
          </div>

  
        
        
        </div>
        </FadeInSection>

        <FadeInSectionRight>
        <div className="choose-img-wrap">
        <img src={process.env.PUBLIC_URL + '/why-choose.jpg'} />
        </div>
        </FadeInSectionRight>

        </div>
      </div>

      <div className="funding-journey">
        <FadeInSectionTop>
        <span className="heading-wrap-left heading-wrap-right heading-wrap-left-white heading-wrap-right-white">
          <h5>Funding Journey</h5>
        </span>
        <h1>A Step-by-Step Guide to Acquiring Business Finance</h1>
        <p>At LA Capital Group, understanding the unique financial needs of each business is at the core of what we do. Our approach prioritizes personalized service and a streamlined process to ensure you get the capital you need without the wait. Trust in our expert team to guide you with clarity and support at every step, from application to funding.</p>
        </FadeInSectionTop>
        <FadeInSectionBottom>
        <div className="funding-boxes">

          <div>
            <div className="funding-box">
              <div>
              <h4>Submit Your Application</h4>
              </div>
              <div>
              <h4>Submit Your Application</h4>
              <p>Fill out the offline application form and provide the most recent three months of your business bank statements.</p>
              </div> 
            </div>
          </div>

          <div className="funding-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path></svg>
          </div>

          <div>
            <div className="funding-box">
              <div>
                <h4>Review & Offer</h4>
              </div>
              <div>
                <h4>Review & Offer</h4>
                <p>Our meticulous underwriting team assesses your application to maximize the funding for your business. A Funding Specialist will then contact you to go over your funding alternatives.</p>
              </div>
            </div>
          </div>

          <div className="funding-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path></svg>
          </div>

          <div>
            <div className="funding-box">
              <div>
                <h4>Agreement & Disbursement</h4>
              </div>
              <div>
                <h4>Agreement & Disbursement</h4>
                <p>Upon agreeing to the terms and returning the signed contract, our Underwriting Team will arrange a call with you for a quick verification interview, after which your funds will be promptly disbursed.</p>
              </div>
            </div>
          </div>

        </div> 
        </FadeInSectionBottom>
      
      </div>
      <div className="services">
        <a name="services" className="anchor"></a>
        <div className="services-inner">
          <div>
            <FadeInSectionTop>
            <span className="heading-wrap-left heading-wrap-right">
            <h5>Services</h5>
            </span>
            <h1>Comprehensive Financial Solutions</h1>
            </FadeInSectionTop>
            <FadeInSectionBottom>
            <div className="service-boxes">
              <div>
                <div className="service-box-img">
                  <img src={process.env.PUBLIC_URL + '/BusinessLine.png'} />
                </div>
                <div className="service-box-text">
                  <h4>Business Line of Credit</h4>
                  <p>A flexible credit facility that allows your business to draw funds as needed and manage cash flow for...</p>
                  <a href="/business-line-of-credit">Read More</a>
                </div>
              </div>

              <div>
              <div className="service-box-img">
                <img src={process.env.PUBLIC_URL + '/short-term-funding.jpg'} />
                </div>
                <div className="service-box-text">
                  <h4>Short-Term Funding</h4>
                  <p>Designed for immediate business needs, this funding solution provides a quick capital boost to help you...</p>
                  <a href="/short-term-funding">Read More</a>
                </div>
              </div>

              <div>
              <div className="service-box-img">
                <img src={process.env.PUBLIC_URL + '/inventory-funding.jpg'} />
                </div>
                <div className="service-box-text">
                  <h4>Inventory Financing</h4>
                  <p>Support your business in maximizing its operational efficiency and sales potential by ensuring you...</p>
                  <a href="/inventory-financing">Read More</a>
                </div>
              </div>

              <div>
              <div className="service-box-img">
                <img src={process.env.PUBLIC_URL + '/BridgeLoans.png'} />
                </div>
                <div className="service-box-text">
                  <h4>Bridge Loans</h4>
                  <p>Short-term loans that provide immediate cash flow when waiting for a longer-term financing solution or the...</p>
                  <a href="/bridge-loans">Read More</a>
                </div>
              </div>

              <div>
              <div className="service-box-img">
                <img src={process.env.PUBLIC_URL + '/Equipment.png'} />
                </div>
                <div className="service-box-text">
                  <h4>Equipment Financing</h4>
                  <p>Financing options to purchase or lease new or used equipment. This service enables your business to stay...</p>
                  <a href="/equipment-financing">Read More</a>
                </div>
              </div>

              <div>
              <div className="service-box-img">
                <img src={process.env.PUBLIC_URL + '/invoice-financing.jpg'} />
                </div>
                <div className="service-box-text">
                  <h4>Invoice Financing</h4>
                  <p>A financial service where your business sells its accounts receivable at a discount to free up cash. It's an...</p>
                  <a href="/invoice-financing">Read More</a>
                </div>
              </div>
            </div>
            </FadeInSectionBottom>
          </div>
        </div>
      </div>

      <div className="contact">
        <a name="contact" className="anchor"></a>
        <div className="contact-inner">
          <div>
            <div className="contact-left">
              <FadeInSection>
              <h1>Get In Touch</h1>
              <p>Reach out to us for personalized financial support and expert advice.</p>
              <div className="contact-left-row">
                <a href="tel:+18886687960">
                  <svg aria-hidden="true" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                </a>
                <span>
                  <h5>Phone</h5>
                  <p>+1 888 668 7960</p>
                </span>
              </div>
              <div className="contact-left-row">
                <a href="mailto:info@lacapitalgrp.com">
                  <svg aria-hidden="true" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                </a>
                <span>
                  <h5>Email</h5>
                  <p>info@lacapitalgrp.com</p>
                </span>
              </div>
              <h4>Follow Our Social Media</h4>
              <div className="socials">
                <a href="https://www.facebook.com/share/GzUSLW51A6hL9HRR/?mibextid=qi2Omg" target="_blank">
                  <svg viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                </a>
                <a href="https://instagram.com/lacapitalgrp" target="_blank">
                <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
                </a>
              </div>
              </FadeInSection>
            </div>
            <div className="contact-right">
              <FadeInSectionBottom>
              <h1>Contact Us</h1>
              <p>Fill out the form below to connect with our team and discover how we can help your business thrive.</p>
              <form action="https://postmail.invotes.com/send"
    method="post" id="email_form" onSubmit={(e) => handleSubmit(e)}>

    <input type="hidden" name="access_token" value="qugkvo8zj91ugz2d280lo6gh" />
    <input type="hidden" name="success_url" value=".?message=Email+Successfully+Sent%21&isError=0" />
    <input type="hidden" name="error_url" value=".?message=Email+could+not+be+sent.&isError=1" />
   
    
    <input required type="text" name="extra_first_name"
                placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
                <input required type="text" name="extra_last_name"
                placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
                <div className="flex-break"></div>
   <input required type="text" name="reply_to"
                placeholder="Your Email" value={email} onChange={e => setEmail(e.target.value)} />
                <input required type="text" name="extra_phone"
                placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
                                <div className="flex-break"></div>

    <input required type="text" name="subject" placeholder="Subject" value={subject} onChange={e => setSubject(e.target.value)} />
    <div className="flex-break"></div>

    <textarea required name="text" placeholder="Message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
    <div className="flex-break"></div>
    <input id="submit_form" type="submit" value={value} disabled={disabled} />
              </form>
              </FadeInSectionBottom>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
